// -----------------------------------------------------------------------------
// Mise en forme du contenu de la page
// -----------------------------------------------------------------------------

.app-content {
  @include grid($vertical: true, $wrap: false);
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
}

// Fil d'ariane

.content-breadcrumb {
  padding: $spacer / 2 0;
  background-color: $gray-200;
}

.breadcrumb {
  font-size: $small-font-size;
  text-transform: uppercase;
}

// Titre de la page

.content-header {
  @include grid-cell(shrink);
  padding: 0.5rem 0;
  background-color: $primary-light;
  border-bottom: 1px solid rgba($primary-dark, 0.1);

  .content-breadcrumb + & {
    border-top: 1px solid rgba($primary-dark, 0.1);
  }
}

.content-header-title {
  margin-bottom: 0;
  font-size: $h4-font-size;
  font-weight: $font-weight-bold;
  color: $primary-dark;

  @include media-breakpoint-up(md) {
    font-size: $h3-font-size;
  }
}

.title-buttons {
  padding-top: 0.5rem;
  margin: 0.25rem 0 0;
  border-top: 1px solid $hr-color;

  @include media-breakpoint-up(sm) {
    padding-top: 0;
    margin-top: 0;
    border-top: 0;
  }

  @include media-breakpoint-down(md) {
    .btn {
      @include button-size(
        $btn-padding-y-sm,
        $btn-padding-x-sm,
        $btn-font-size-sm,
        $btn-border-radius-sm
      );
    }
  }
}

// Conteneur principal

.content-container {
  @include grid-cell(auto);
  position: relative;
  margin-top: $spacer;
}
