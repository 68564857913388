// -----------------------------------------------------------------------------
// Définition des polices utilisées
// -----------------------------------------------------------------------------

/// Montserrat
/// OFL - Julieta Ulanovsky

$montserrat-font-path: '~fonts/montserrat';

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: $font-weight-normal;
  src: url('#{$montserrat-font-path}/montserrat-regular.woff2') format('woff2'),
    url('#{$montserrat-font-path}/montserrat-regular.woff') format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: $font-weight-normal;
  src: url('#{$montserrat-font-path}/montserrat-italic.woff2') format('woff2'),
    url('#{$montserrat-font-path}/montserrat-italic.woff') format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: $font-weight-medium;
  src: url('#{$montserrat-font-path}/montserrat-medium.woff2') format('woff2'),
    url('#{$montserrat-font-path}/montserrat-medium.woff') format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: $font-weight-medium;
  src: url('#{$montserrat-font-path}/montserrat-mediumitalic.woff2')
      format('woff2'),
    url('#{$montserrat-font-path}/montserrat-mediumitalic.woff') format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url('#{$montserrat-font-path}/montserrat-bold.woff2') format('woff2'),
    url('#{$montserrat-font-path}/montserrat-bold.woff') format('woff');
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-weight: $font-weight-bold;
  src: url('#{$montserrat-font-path}/montserrat-bolditalic.woff2')
      format('woff2'),
    url('#{$montserrat-font-path}/montserrat-bolditalic.woff') format('woff');
}
