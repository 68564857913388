// -----------------------------------------------------------------------------
// Étends le composant Modal
// -----------------------------------------------------------------------------

.modal-header {
  background-color: $card-cap-bg;

  + .modal-footer {
    border-top: 0;
  }
}

.modal-body {
  > :last-child {
    margin-bottom: 0;
  }
}
