// -----------------------------------------------------------------------------
// Variables de l'application et de Bootstrap
// -----------------------------------------------------------------------------

//
// Bootstrap
// voir : ../../node_modules/bootstrap/scss/_variables.scss
//

// Color system

$white: #fff;
$black: #000;
$gray-000: #f9f9f8;
$gray-100: #eeedeb;
$gray-200: #e2e0dc;
$gray-300: #d6d2cc;
$gray-400: #c8c3bb;
$gray-500: #b8b2a8;
$gray-600: #a79e92;
$gray-700: #938774;
$gray-800: #746a5b;
$gray-900: #443e35;

// https://palx.jxnblk.com/e08405
$blue: #0564e0;
$indigo: #1405e0;
$purple: #8105e0;
$fuschia: #e005d1;
$pink: #e00564;
$red: #e01405;
$orange: #f89306;
$yellow: #edfa00;
$lime: #64e005;
$green: #05e014;
$teal: #05e081;
$cyan: #05d1e0;

$primary: #e08405;
$primary-light: #f7e0c1;
$primary-dark: #925403;

$light: $gray-100;
$dark: $gray-800;

$min-contrast-ratio: 4.5;

$color-contrast-dark: $black;
$color-contrast-light: $white;

// Options

$enable-validation-icons: false;

// Spacing

$spacer: 1rem;

// Body

$body-bg: $gray-000;
$body-color: $gray-900;

// Links

$link-color: $primary-dark;
$link-decoration: none;
$link-hover-color: $black;
$link-hover-decoration: none;

// Components

$border-color: rgba($black, 0.15);

$border-radius: 0.2rem;
$border-radius-lg: 0.25rem;
$border-radius-sm: 0.15rem;

$box-shadow: 0 0.25rem 0.75rem rgba($black, 0.15);
$box-shadow-sm: 0 0.1rem 0.2rem rgba($black, 0.075);
$box-shadow-lg: 0 0.5rem 1rem rgba($black, 0.175);

$component-active-color: $white;
$component-active-bg: $primary;

// Typography

// stylelint-disable-next-line value-keyword-case
$font-family-sans-serif: Montserrat, Arial, sans-serif;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$headings-font-weight: $font-weight-medium;

$small-font-size: 0.8125em;

$hr-color: rgba($black, 0.1);
$hr-opacity: 1;

// Tables

$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 0.75rem;
$table-cell-padding-x-sm: 0.3rem;
$table-cell-padding-y-sm: 0.3rem;

$table-bg: $white;

$table-th-font-weight: $font-weight-medium;

$table-group-separator-color: $border-color;

$table-bg-scale: -60%;

// Buttons + Forms

$input-btn-focus-width: 0;
$input-btn-focus-box-shadow: none;

$input-btn-padding-y-sm: 0.2rem;
$input-btn-padding-x-sm: 0.4rem;

// Buttons

$btn-disabled-opacity: 0.5;
$btn-active-box-shadow: none;

// Forms

$input-bg: $white;

$input-color: $gray-800;
$input-focus-color: $gray-900;
$input-placeholder-color: $gray-400;

$input-transition: color 0.1s ease-in-out, border-color 0.1s ease-in-out;

$form-check-input-width: 1.125em;

$form-check-input-bg: $input-bg;

// Navbar

$navbar-dark-color: rgba($white, 0.75);
$navbar-dark-hover-color: $white;
$navbar-dark-active-color: $white;

// Pagination

$pagination-border-color: $border-color;

// Cards

$card-border-color: $border-color;
$card-cap-padding-y: $spacer * 0.75;

// Badges

$badge-font-weight: $font-weight-medium;
$badge-padding-y: 0.25em;
$badge-padding-x: 0.5em;

// Modals

$modal-header-padding-y: $card-cap-padding-y;

// Alerts

$alert-padding-y: $card-cap-padding-y;

$alert-bg-scale: -50%;
$alert-border-scale: -40%;
$alert-color-scale: 75%;

// List group

$list-group-border-color: $border-color;

$list-group-item-bg-scale: -60%;
$list-group-item-color-scale: 75%;

$list-group-action-color: $dark;

// Breadcrumbs

$breadcrumb-margin-bottom: 0;

// Close

$btn-close-width: 0.75em;
$btn-close-padding-x: 0.5em;
