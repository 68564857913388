// -----------------------------------------------------------------------------
// Étends le composant List group
// -----------------------------------------------------------------------------

// Variations
//
// N'atténue pas les couleurs pour `light` et `dark`.

.list-group-item-light {
  color: color-contrast($light);
  background-color: $light;
}

.list-group-item-dark {
  color: color-contrast($dark);
  background-color: $dark;
}
