// -----------------------------------------------------------------------------
// Mise en forme du pied de page
// -----------------------------------------------------------------------------

.app-footer {
  padding: 0.5rem 0;
  border-top: 1px solid $hr-color;
}

.footer-meta {
  padding: 0 0.5rem;
  margin-bottom: 0;
  font-size: 80%;
  color: $dark;
}

.footer-nav {
  font-size: 90%;

  .nav-link {
    padding: 0 0.5rem;
  }
}
