// -----------------------------------------------------------------------------
// Mise en forme de l'en-tête, composé de la barre de navigation
// -----------------------------------------------------------------------------

.navbar {
  z-index: $zindex-sticky;
  background-color: $dark;
  box-shadow: $box-shadow-sm;
}

.navbar-dark {
  .navbar-text a {
    color: $navbar-dark-color;

    &:hover,
    &:focus {
      color: $navbar-dark-hover-color;
    }
  }
}

@include media-breakpoint-down(lg) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: $zindex-fixed;
    background-color: $dark;
    border-top: 1px solid rgba($black, 0.1);
    box-shadow: $box-shadow-sm;

    .navbar-nav .nav-link {
      padding-right: $navbar-nav-link-padding-x;
      padding-left: $navbar-nav-link-padding-x;
    }

    .dropdown-menu {
      padding: 0;
      margin: 0 0 map-get($spacers, 1);
      border-right: 0;
      border-left: 0;
      border-radius: 0;
    }

    .dropdown-item {
      white-space: normal;
    }
  }
}
