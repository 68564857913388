// -----------------------------------------------------------------------------
// Étends la mise en forme des tableaux
// -----------------------------------------------------------------------------

$table-th-bg: $card-cap-bg;

.table {
  border: $table-border-width solid $table-border-color;

  thead {
    --bs-table-bg: $table-th-bg;
  }

  // En-tête d'une colonne ordonnable.
  .orderable {
    > a {
      color: $primary-dark;
      white-space: nowrap;

      &:hover {
        color: $black;
      }

      &::before {
        @include fa-icon;
        margin-right: 0.4rem;
        color: rgba($black, 0.3);
      }
    }

    &.desc > a::before {
      content: fa-content($fa-var-long-arrow-down);
    }

    &.asc > a::before {
      content: fa-content($fa-var-long-arrow-up);
    }
  }

  // Cellule contenant des actions sur l'élément de la ligne.
  .row-actions {
    --bs-table-bg: $table-th-bg;

    width: 1px;
    padding: 0 0.25rem;
    white-space: nowrap;
    border-left-width: 1px;

    > a {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 28px;
      height: 28px;

      &:not(:first-child) {
        margin-left: 0.25rem;
      }
    }
  }
}

.table-borderless {
  --bs-table-bg: transparent;

  border: 0;

  .row-actions {
    border-left-width: 0;
  }
}
