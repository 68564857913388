// -----------------------------------------------------------------------------
// Étends le composant Alert
// -----------------------------------------------------------------------------

.messages-holder {
  position: fixed;
  top: $spacer;
  right: 0;
  z-index: $zindex-tooltip;
  width: 100%;
  max-width: 450px;
  padding: 0 $spacer;

  .alert {
    box-shadow: $box-shadow-sm;
    backdrop-filter: blur(10px);
    opacity: 0.9;
    transition: $transition-fade;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      opacity: 1;
    }

    &.fade:not(.show) {
      opacity: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    font-size: $font-size-sm;
  }
}

.alert-body {
  > :last-child {
    margin-bottom: 0;
  }
}

// Variations
//
// N'atténue pas les couleurs pour `light` et `dark`.

.alert-light {
  color: color-contrast($light);
  background-color: $light;
  border-color: color-level($light, 1);
}

.alert-dark {
  color: color-contrast($dark);
  background-color: $dark;
  border-color: color-level($dark, 1);
}
