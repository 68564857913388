// -----------------------------------------------------------------------------
// Utilitaires de base pour l'application
// -----------------------------------------------------------------------------

// Masque un élément lorsque JavaScript est désactivé.
.no-js .d-nojs-none {
  display: none;
}

// Conteneur d'un élément .stretched-link cliquable.
.stretched-container {
  position: sticky;
  transform: rotate(0);
}
