// Grille et cellules
//
// Les mots-clés possibles pour la taille d'une cellule sont :
//   - auto : occupe tout l'espace restant
//   - full : occupe toute la longueur ou hauteur
//   - shrink : occupe uniquement l'espace requis
//   - grow : occupe l'espace requis et tout l'espace restant

// Définis les propriétés de base d'une cellule de grille.
//
// @param {Keyword} $size [full] - La taille de la cellule
// @param {Boolean} $vertical [false] - Si la cellule est disposée verticalement.
@mixin grid-cell($size: 'full') {
  @if $size == 'full' {
    flex: 0 0 auto;
    min-width: 0px; // stylelint-disable-line length-zero-no-unit
    min-height: 0px; // stylelint-disable-line length-zero-no-unit
  } @else if $size == 'auto' {
    flex: 1 1 0px; // stylelint-disable-line length-zero-no-unit
  } @else if $size == 'shrink' {
    flex: 0 0 auto;
  } @else if $size == 'grow' {
    flex: 1 0 auto;
  }
}

// Définis un conteneur pour une grille.
//
// @param {Boolean} $vertical [false] - Si les cellules sont disposées verticalement.
// @param {Boolean} $wrap [true] - Si les cellules peuvent être sur plusieurs lignes.
@mixin grid($vertical: false, $wrap: true) {
  $direction: if($vertical, column, row);
  $wrap: if($wrap, wrap, nowrap);

  display: flex;
  flex-flow: $direction $wrap;
}
