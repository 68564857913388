// -----------------------------------------------------------------------------
// Étends le composant Card
// -----------------------------------------------------------------------------

.card {
  overflow: hidden;

  &:disabled {
    background-color: $light;
  }
}

.card-body {
  > :last-child {
    margin-bottom: 0;
  }
}

.card-header + .list-group {
  border-top-width: 0;
}
