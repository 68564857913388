// -----------------------------------------------------------------------------
// Étends la mise en forme des formulaires
// -----------------------------------------------------------------------------

// Définis le curseur des champs et labels désactivés.
.form-control:disabled,
.form-check-input:disabled ~ .form-check-label {
  cursor: not-allowed;
}

// Définis le curseur des champs checkbox et radio.
.form-check-input,
.form-check-label {
  cursor: pointer;
}

// Masque les options désactivées des select.
.form-select > :disabled {
  display: none;
}

// Étends les classes de base.

.required {
  font-size: 90%;
  color: $danger;
}

// Conteneur d'un champ.
.form-group {
  margin-bottom: $spacer;
}

// Conteneur des actions du formulaire.
.form-actions {
  &::before {
    display: block;
    width: 100%;
    margin-bottom: $spacer;
    content: '';
    border-top: 2px solid $hr-color;
  }

  &.row::before {
    margin-right: $grid-gutter-width / 2;
    margin-left: $grid-gutter-width / 2;
  }
}
