// -----------------------------------------------------------------------------
// Agencement général de l'application
// -----------------------------------------------------------------------------

.app-container {
  @include grid($vertical: true, $wrap: false);
  position: relative;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.app-header,
.app-footer {
  @include grid-cell(shrink);
  width: 100%;
}

.app-content {
  @include grid-cell(grow);
  position: relative;
}
