// -----------------------------------------------------------------------------
// Styles de la page de connexion
// -----------------------------------------------------------------------------

.login-container {
  height: 100%;
}

.login-content {
  width: 100%;
  // évite la double bordure avec le pied de page
  margin-bottom: -1px;

  @include media-breakpoint-down(sm) {
    .card {
      border-width: 1px 0;
      border-radius: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .login-container {
    @include grid($vertical: true, $wrap: false);
    align-items: center;
    justify-content: center;
    margin-top: 0;
  }

  .login-content {
    @include grid-cell(shrink);
    max-width: 460px;
  }
}

.login-logo {
  padding: 0 0.5rem;
  margin-bottom: 1rem;
  text-align: center;

  @include media-breakpoint-up(md) {
    margin-bottom: 1.5rem;
  }
}
